import React from "react";
import cn from "classnames";

import { Router, Route, Link } from "react-router-dom";
import { connect } from "react-redux";

import { NEW_ERRORS } from "patient_app/constants/actionTypes";

import Nav from "patient_app/components/navbar/Nav";

import dashboardBlockStyles from "patient_app/stylesheets/appointments/dashboardBlockStyles";
import communityTabStyles from "patient_app/stylesheets/communityTab/communityTabStyles";
import bookingStyles from "patient_app/stylesheets/curriculums/bookingStyles";

const mapDispatchToProps = (dispatch) => {
  return {
    onErrors: (errors) => dispatch({ type: NEW_ERRORS, errors: errors }),
  };
};

const mapStateToProps = (state) => {
  return {};
};

class CommunityTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
    };
  }
  componentDidMount = () => {
    let host = "https://app.workithealth.com";
    if (
      window.location.hostname &&
      window.location.hostname.startsWith("staging")
    ) {
      host = "https://staging.workithealth.com";
    } else if (
      window.location.hostname &&
      window.location.hostname.startsWith("wc.staging")
    ) {
      host = "https://wc.staging.workithealth.com";
    } else if (
      window.location.hostname &&
      window.location.hostname.startsWith("dev")
    ) {
      host = "https://dev.workithealth.com";
    } else if (
      window.location.hostname &&
      window.location.hostname.startsWith("demophi")
    ) {
      host = "https://demophi.workithealth.com";
    } else if (
      (window.location.hostname &&
        window.location.hostname.startsWith("local")) ||
      (window.location.hostname && window.location.hostname.includes("ngrok"))
    ) {
      host = `http://localhost:3000`;
    }

    this.setState({ url: host });
  };

  componentDidUpdate = async (prevProps) => {};

  render() {
    let title = "Group";

    return (
      <div className="Patient-App-Container">
        {/* {user && ( */}
        <div>
          <Nav />
          <div
            id="App-Body-Content"
            className={cn("App-Community-Show")}
            role="main"
          >
            <div>
              <div className="Tabs-Community">
                <div className="inner-cont">
                  <div className="header">
                    <h2>{title}</h2>

                    <h1>
                      Please download the Workit Health mobile app to access
                      Groups.
                    </h1>
                  </div>
                  <p>
                    Workit Health groups are not fully available on our web app.
                  </p>
                  <p>
                    To browse available groups and enroll in them,{" "}
                    <a href="https://itunes.apple.com/us/app/id1383957273">
                      download the app on iOS
                    </a>{" "}
                    or{" "}
                    <a href="https://play.google.com/store/apps/details?id=com.workithealth.workitapp">
                      download the app on Android.
                    </a>{" "}
                  </p>
                  <p>
                    If you are already enrolled in a group and looking for the
                    link to join a current meeting,{" "}
                    <a href={`${this.state.url}/appointments`}>
                      go to the Action Item tab on the dashboard
                    </a>{" "}
                    of the mobile app and join via that group’s Action Item.
                  </p>
                  <p>
                    If you have questions,{" "}
                    <a href={`${this.state.url}/coach_chat`}>
                      chat with your counselor team.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <style jsx>{dashboardBlockStyles}</style>
        <style jsx>{communityTabStyles}</style>
        <style jsx>{bookingStyles}</style>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommunityTab);
